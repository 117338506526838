
    /**
     * 
     * @returns the scrollBarWidth for infinite scroll use
     */
    
    export const scrollbarWidth = () => {
        // thanks too https://davidwalsh.name/detect-scrollbar-width
        const scrollDiv = document.createElement("div");
        scrollDiv.setAttribute(
            "style",
            "width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;"
        );
        document.body.appendChild(scrollDiv);
        const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        document.body.removeChild(scrollDiv);
        return scrollbarWidth;
    };